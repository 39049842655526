import { ContactDetail } from 'models';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const trackDechetsUrl = `${API_URL}track-dechets`;

// GET
const getConnectionStatus = () => RequestHelper.GET(`${trackDechetsUrl}`);
const getTrackDechetsInfos = () => RequestHelper.GET(`${trackDechetsUrl}/check`);
const getTrackDechetsContacts: () => Promise<ContactDetail[]> = () => RequestHelper.GET(`${trackDechetsUrl}/contacts`);

// POST
const setTrackDechetsKey = apiKey => RequestHelper.POST(`${trackDechetsUrl}`, { apiKey });

export const TrackDechetsService = {
  getConnectionStatus,
  getTrackDechetsInfos,
  getTrackDechetsContacts,
  setTrackDechetsKey
};
