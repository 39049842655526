import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { FormAddress, FormLine, SelectLabel } from 'components';
import FormCountryIdentifier from 'components/Form/FormCountryIdentifier/FormCountryIdentifier';
import { ModalHeader } from 'components/Modals/ModalHeader';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { CompanyService } from 'services';
import { DISTRIBUTOR_ROLES } from 'utils/constants';
import { translate, UserHelper } from 'utils/helpers';

import { CustomActionButtons } from './_CustomActionButtons';

export const CreateInstitutionModal = ({
  onConfirm,
  onClose,
  closeOnSubmit,
  defaultValues,
  parentInstitutionGroupId,
  parentCompanyId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [identifierFields, setIdentifierFields] = useState([]);
  const [formState, {
    text,
    checkbox,
    raw
  }] = useFormState({
    ...defaultValues,
    fileNumberPrefix: defaultValues.fileNumberPrefix ?? '',
    activated: defaultValues.activated !== null ? defaultValues.activated : true,
    attestationNumber: defaultValues.attestationNumber ?? '',
    address1: (defaultValues.address && defaultValues.address?.address1) ?? '',
    address2: (defaultValues.address && defaultValues.address?.address2) ?? '',
    postalCode: (defaultValues.address && defaultValues.address?.postalCode) ?? '',
    city: (defaultValues.address && defaultValues.address.city) ?? '',
    country: (defaultValues.address && defaultValues.address.country) ?? '',
    automaticCerfaDelivery: (defaultValues.config?.automaticCerfaDelivery) ?? false,
    institutionName: defaultValues.name ?? ''
  });

  useEffect(() => {
    if (defaultValues.id) {
      formState.setField('isPublic', defaultValues.isPublic);
    } else {
      CompanyService.isPublic(parentCompanyId)
        .then((isPublic) => formState.setField('isPublic', isPublic));
    }
    // eslint-disable-next-line
  }, [parentCompanyId, defaultValues]);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    setIsLoading(true);
    const isFormValid = document.forms.institutionForm && document.forms.institutionForm.reportValidity();

    if (isFormValid) {
      const newInstitution = {
        ...formState.values,
        identifiers: identifierFields,
        id: defaultValues.id || null,
        name: formState.values.institutionName || '',
        config: {
          automaticCerfaDelivery: formState.values.automaticCerfaDelivery ?? false
        },
        address: {
          hashId: (defaultValues.address && defaultValues.address.hashId) || null,
          address1: formState.values.address1,
          address2: formState.values.address2,
          postalCode: formState.values.postalCode,
          city: formState.values.city,
          country: {
            ...formState.values.country,
            countryCode: formState.values.country && formState.values.country.countryCode
          }
        },
        parentInstitutionGroupId,
        parentCompanyId
      };

      onConfirm(newInstitution)
        .finally(() => setIsLoading(false));

      if (closeOnSubmit) {
        onClose();
      }
    }
  }, [formState, closeOnSubmit, onClose, onConfirm, defaultValues, parentInstitutionGroupId, parentCompanyId, identifierFields]);

  return (
    <form autoComplete="off" name="institutionForm" style={{ overflow: 'auto' }} onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding}/>
        <Typography component="span">
          {defaultValues && defaultValues.name
            ? translate('modalInstitution.editInstitution')
            : translate('modalInstitution.newInstitution')}
        </Typography>
      </ModalHeader>

      <DialogContent style={{ minWidth: '650px' }}>
        <Grid container direction="column">
          <FormCountryIdentifier
            columnSize={12}
            formState={formState}
            hasDefaultSiret={defaultValues.siret}
            identifierFields={identifierFields}
            raw={raw}
            setIdentifierFields={setIdentifierFields}
            text={text}
          />

          {!UserHelper.hasAccessRight(DISTRIBUTOR_ROLES) && (
            <>
              <FormControl>
                <FormLine>
                  <TextField
                    autoComplete="off"
                    inputProps={{ maxLength: 32 }}
                    label={translate('common.attestationNumber')}
                    name="attestationNumber"
                    {...text('attestationNumber')}
                  />
                </FormLine>
              </FormControl>
              <FormControl>
                <FormLine>
                  <TextField
                    autoComplete="off"
                    inputProps={{ maxLength: 32 }}
                    label={translate('modalInstitution.customFileNumber')}
                    name="fileNumberPrefix"
                    {...text('fileNumberPrefix')}
                  />
                </FormLine>
              </FormControl>
            </>
          )}

          <FormAddress
            formState={formState}
            isRequired
            text={text}
            withCountry={false}
          />

          {!UserHelper.hasAccessRight(DISTRIBUTOR_ROLES) && (
            <>
              <SelectLabel>{translate('common.cerfaAutomaticDelivery')}</SelectLabel>

              <FormControl>
                <FormLine>
                  <FormControlLabel
                    control={<Checkbox value={formState.values.automaticCerfaDelivery}/>}
                    key="active"
                    label={translate('common.activated')}
                    {...checkbox('automaticCerfaDelivery')}
                  />
                </FormLine>
              </FormControl>
            </>
          )}
        </Grid>
      </DialogContent>

      <CustomActionButtons
        isLoading={isLoading}
        isValidated={defaultValues.validated}
        onClose={onClose}
      />
    </form>
  );
};

CreateInstitutionModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({})
};

CreateInstitutionModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: {}
};
